<template>
  <div class="admin-list">
    <PageTitle :title="$t('page.adminSetting.title')" :btn="$t('common.button.create.text')" @btnClick="openDialog('create')" />
    <section class="flex-end">
      <!-- <SearchInput type="single" placeholder="請輸入使用者ID" :data.sync="userSearch"/> -->
    </section>

    <section>
      <BaseTable v-loading="loading.table" :data="tableData" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="displayText.tableColumn.name" prop="name" align="center" />
        <BaseElTableColumn
          :label="$t('adminSetting.table.email.title')"
          prop="AuthEmail.email"
          align="center"
        />
        <BaseElTableColumn :label="$t('adminSetting.table.role.title')" prop="role" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.role)">
              {{
                roleTrans(scope.row.role)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <!-- <BaseElTableColumn label="ID" prop="id" align="center"/> -->

        <BaseElTableColumn fixed="right" :label="$t('common.table.action.text')" width="100" align="center">
          <template slot-scope="user">
            <el-popover placement="top">
              <div v-if="selectRow" class="table-edit-btn-popover">
                <BaseElButton
                  type="text"
                  @click="
                    openDialog('update'),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editInfo.title') }}
                </BaseElButton>
                <BaseElButton
                  type="text"
                  @click="
                    openDialog('password'),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.dialog.editPassword.title') }}
                </BaseElButton>
                <BaseElButton
                  v-if="!selectRow.roleAllShop && displayShopManageByRole(user.row.role)"
                  type="text"
                  @click="
                    openDialog('shop'),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editBranch.title') }}
                </BaseElButton>
                <BaseElButton
                  type="text"
                  class="delete"
                  @click=";(deleteDialog = true), (selectRow = user.row)"
                >
                  {{ $t('common.button.delete.text') }}
                </BaseElButton>
              </div>
              <TableSettingIconButton slot="reference" @click="selectRow = user.row" />
              <!-- <BaseElButton
                slot="reference"
                type="text"
                class="btn-type-edit-row"
                icon="el-icon-setting"
                @click="selectRow = user.row"
              /> -->
            </el-popover>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <!-- Dialog -->
    <EditAdminModal
      v-if="modal.edit"
      :action="dialogType"
      :selectRow="selectRow"
      @close="onEditModalClose"
      @refresh="refresh"
    />

    <DeleteDialog
      v-if="deleteDialog"
      :title="$t('common.dialog.delete.title')"
      :content="$t('common.dialog.delete.content')"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteUser(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import adminRole from '@/config/admin'

import {
  GetUser,
  DeleteUser,
  GetUsersCount,
  FindCustomRole,
} from '@/api/user'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, emailRules } from '@/validation'
import { i18n } from '@/plugins/i18n/i18n'
import EditAdminModal from './components/EditAdminModal.vue'
import { computed, defineComponent, onMounted, provide, reactive, ref } from 'vue'
import store from '@/store'
import { useTable } from '@/use/table'
import { apiFormatAdaptor } from '@/utils/api'

export default defineComponent({
  name: 'AdminList',
  components: { DeleteDialog, EditAdminModal, EmptyBlock },
  setup () {
    const selectRow = ref(null)
    const modal = reactive({
      edit: false,
    })
    const userSearch = ref('')
    const {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      pageStartIndex,
      fetchData,
      fetchDataCount,
    } = useTable()
    const adminRoleList = ['orgAdmin', 'shopManager', 'shopProvider', 'shopMod', 'shopOperation']
    const userList = ref([])
    const usersCount = ref(0)
    const customRoleOptions = ref([])
    const showDialog = ref(false)
    const dialogType = ref('create')
    const deleteDialog = ref(false)

    const getTableData = async () => {
      const payload = {
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        // name: search.name || undefined,
        // role: search.role || undefined,
      }
      await Promise.allSettled([
        fetchData(apiFormatAdaptor(GetUser), payload),
        fetchDataCount(apiFormatAdaptor(GetUsersCount), payload),
      ])
    }

    const formRules = reactive({
      name: noEmptyRules(),
      email: emailRules(),
      password: [
        { min: 6, message: i18n.t('validation.minLength.text', { number: '6' }), trigger: 'blur' },
        noEmptyRules(),
      ],
      role: noEmptyRules(),
    })

    const displayText = computed(() => {
      const text = {
        tableColumn: {
          name: i18n.t('adminSetting.table.name.title'),
          email: i18n.t('adminSetting.table.email.title'),
          role: i18n.t('adminSetting.table.role.title'),
          action: i18n.t('common.table.action.text'),
        },
      }
      return text
    })

    const curUser = computed(() => store.getters.user)

    const disabledRoleSelect = computed(() => {
      if (!selectRow.value) return false
      return selectRow.value.id === curUser.value.id
    })

    const availableRoleList = computed(() => {
      const availableRoleList = []
      adminRoleList.forEach(item => {
        availableRoleList.push(adminRole[item])
      })
      customRoleOptions.value.map(item => {
        availableRoleList.push({ name: item.name, value: item.role })
      })
      return availableRoleList
    })

    const roleTrans = (role) => {
      const targetRole = availableRoleList.value.find(item => item.value === role)
      return targetRole ? targetRole.name : ''
    }

    const tagType = (val) => {
      const type = {
        orgAdmin: 'warning',
        shopManager: 'action',
        shopProvider: 'info',
        shopMod: 'action',
      }
      return type[val] || 'info'
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      await getUserCustomRole()
      loading.table = false
    }

    const openDialog = async (type) => {
      dialogType.value = type
      showDialog.value = true
      modal.edit = true
    }

    const showManageShop = (role) => {
      const target = customRoleOptions.value.find(item => item.role === role)
      return target ? target.needSettingShops : true
    }

    const deleteUser = async () => {
      try {
        await DeleteUser({ id: selectRow.value.id })
        selectRow.value = null
        await refresh()
        window.$message.success(i18n.t('common.message.deleteSuccess'))
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const getUserCustomRole = async () => {
      try {
        customRoleOptions.value = await FindCustomRole()
      } catch (error) {
        console.log(error)
        window.$message(error)
      }
    }

    const displayShopManageByRole = (role) => {
      const targetRole = customRoleOptions.value.find(item => item.role === role)
      return targetRole ? targetRole.needSettingShops : true
    }

    const onEditModalClose = () => {
      modal.edit = false
      selectRow.value = null
    }

    provide('availableRoleList', availableRoleList)
    provide('customRoleOptions', customRoleOptions)

    onMounted(async () => {
      await refresh()
    })

    return {
      selectRow,
      modal,
      loading,
      userSearch,
      tableOptions,
      adminRoleList,
      userList,
      usersCount,
      showDialog,
      dialogType,
      deleteDialog,
      formRules,
      pageStartIndex,
      curUser,
      disabledRoleSelect,
      roleTrans,
      tagType,
      refresh,
      openDialog,
      showManageShop,
      deleteUser,
      getUserCustomRole,
      displayShopManageByRole,
      tableData,
      tableDataCount,
      displayText,
      onEditModalClose,
    }
  },
})
</script>

<style scoped lang="scss"></style>
